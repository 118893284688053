const MINIMALTIMEGAPINMINUTES = 30;
export const DEFAULTSTARTTIME = "06:00";
export const DEFAULTENDTIME = "23:00";

/**
 * Generates an array of time options in 30-minute intervals between the specified start and end times.
 *
 * @param {string} startTime - The starting time in HH:mm format. Defaults to "06:00".
 * @param {string} endTime - The ending time in HH:mm format. Defaults to "23:00".
 * @returns {string[]} An array of time options in 30-minute intervals.
 */
export const generateTimeOptions = (
  startTime = DEFAULTSTARTTIME,
  endTime = DEFAULTENDTIME,
) => {
  startTime = startTime || DEFAULTSTARTTIME;
  endTime = endTime || DEFAULTENDTIME;

  // Extract hours and minutes from startTime and endTime
  const [startHour, startMinute] = startTime.split(":").map(Number);
  const [endHour, endMinute] = endTime.split(":").map(Number);

  // Initialize an array to store time options
  const timeOptions = [];

  // Iterate over hours from startHour to endHour
  for (let hour = startHour; hour <= endHour; hour++) {
    // Determine the starting and ending minutes for the current hour
    const startLoopMinute = hour === startHour ? startMinute : 0;
    const endLoopMinute = hour === endHour ? endMinute : 60;

    // Iterate over minutes in 30-minute intervals
    for (let minute = startLoopMinute; minute < endLoopMinute; minute += 30) {
      // Format hour and minute with leading zeros
      const formattedHour = hour.toString().padStart(2, "0");
      const formattedMinute = minute === 0 ? "00" : minute.toString();

      // Concatenate formatted hour and minute to create a time option
      const time = `${formattedHour}:${formattedMinute}`;

      // Add the time option to the array
      timeOptions.push(time);
    }
  }

  // Add the endTime to the options
  const formattedEndHour = endHour.toString().padStart(2, "0");
  const formattedEndMinute = endMinute.toString().padStart(2, "0");
  const endTimeOption = `${formattedEndHour}:${formattedEndMinute}`;
  timeOptions.push(endTimeOption);

  // Return the array of time options
  return timeOptions;
};

export const addMinimalRequiredTimeGap = (time) => {
  time = time || DEFAULTSTARTTIME;

  const [startHour, startMinute] = time.split(":").map(Number);
  const dateTime = new Date();

  dateTime.setHours(startHour, startMinute, 0);
  dateTime.setMinutes(dateTime.getMinutes() + MINIMALTIMEGAPINMINUTES);

  return `${dateTime.getHours()}:${dateTime.getMinutes()}`;
};
