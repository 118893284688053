<template>
  <div class="menu tw-border-b" :class="route.meta.layout">
    <div
      :class="{
        'tw-z-[100]': hovered,
      }"
      @mouseenter="hovered = true"
      @mouseleave="hovered = false"
      class="tw-relative tw-top-0 tw-mx-auto tw-hidden tw-bg-white tw-text-left lg:tw-block"
    >
      <div
        class="tw-h-2 tw-w-full tw-bg-secondary"
        v-if="globals.statusMessage && !globals.statusMessage.active"
      ></div>
      <div
        class="tw-mx-auto tw-flex tw-w-full tw-max-w-[1480px] tw-items-center tw-justify-between tw-px-4"
      >
        <div class="tw-font-medium">
          {{ schoolname }}
        </div>

        <div
          class="tw-flex tw-h-full tw-items-center tw-gap-x-2 md:tw-gap-x-0.5"
        >
          <template v-for="(item, key) in menuItems" :key="key">
            <router-link
              :to="item.to"
              class="menu__link tw-group"
              :class="[
                {
                  'router-link-active':
                    route.name === 'updateMeeting' &&
                    checkMeetings(true) &&
                    item.name === 'Overzichten',
                },
                {
                  'router-link-active':
                    route.name === 'updateMeeting' &&
                    checkMeetings(false) &&
                    item.name === 'Beheer',
                },
              ]"
            >
              {{ item.name }}

              <div class="menu__submenu" v-if="item.subMenu">
                <router-link
                  v-for="(subItem, subKey) in item.subMenu"
                  :to="subItem.to"
                  :key="subKey"
                  class="menu__sublink"
                  >{{ subItem.name }}</router-link
                >
              </div>
            </router-link>
          </template>

          <div class="menu__link tw-group" v-if="locations && locations.length">
            <q-icon name="fa-thin fa-location-dot" size="20px" />
            <div class="menu__submenu">
              <div
                class="menu__sublink"
                v-for="(location, key) in locations"
                :key="key"
                @click="loginAsSchool(location)"
              >
                {{ location.name }}
              </div>
            </div>
          </div>
          <HeaderMenuIcons />
        </div>
      </div>
    </div>

    <div
      class="tw-sticky tw-top-0 tw-z-[104] tw-mx-auto tw-bg-white tw-text-left tw-shadow lg:tw-hidden"
    >
      <div class="tw-h-2 tw-w-full tw-bg-secondary"></div>
      <div
        class="tw-mx-auto tw-flex tw-max-w-screen-2xl tw-items-center tw-justify-between tw-px-4 tw-py-2"
      >
        <div class="tw-w-[175px] tw-overflow-hidden tw-text-ellipsis">
          <img
            class="tw-w-[125px]"
            src="/images/logo_no_tagline.svg"
            alt="Logo Catwise"
          />
        </div>
        <div>
          <div
            class="hamburger"
            @click="toggleMenu()"
            :class="{ 'is-open': globals.menuOpen }"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="tw-fixed tw-right-4 tw-top-4 tw-z-[110] tw-w-[90%] tw-rounded-lg tw-bg-white tw-p-4 tw-text-left tw-transition-all tw-delay-75 tw-duration-300 tw-ease-in-out md:tw-w-2/3 lg:tw-hidden"
      :class="{
        'tw-translate-x-0': globals.menuOpen,
        'tw-translate-x-full': !globals.menuOpen,
      }"
    >
      <div class="tw-flex tw-justify-between">
        <div class="tw-w-[175px] tw-overflow-hidden tw-text-ellipsis">
          <img
            class="tw-w-[125px]"
            src="/images/logo_no_tagline.svg"
            alt="Logo Catwise"
          />
          <span>{{ schoolname }}</span>
        </div>
        <div
          class="hamburger"
          @click="toggleMenu()"
          :class="{ 'is-open': globals.menuOpen }"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div
        class="no-scrollbar tw-mt-4 tw-h-screen tw-overflow-y-scroll tw-border-t tw-pb-52 tw-pt-4"
      >
        <template v-for="(item, key) in menuItems"
          ><router-link
            :to="item.to"
            :key="key"
            class="tw-block"
            v-if="item.visible"
          >
            <div
              class="tw-rounded-lg tw-px-2 tw-py-1 tw-font-bold hover:tw-bg-gray-100"
            >
              {{ item.name }}
            </div>

            <div class="tw--mx-4 tw-bg-white tw-px-4" v-if="item.subMenu">
              <router-link
                v-for="(subItem, subKey) in item.subMenu"
                :to="subItem.to"
                :key="subKey"
                class="tw-mx-4 tw-mb-1 tw-block tw-rounded-lg tw-px-2 tw-py-1 hover:tw-bg-gray-100 [&.router-link-active]:tw-bg-gray-100 [&.router-link-active]:hover:tw-no-underline"
                >{{ subItem.name }}</router-link
              >
            </div>
          </router-link>
        </template>
        <div
          class="tw-cursor-pointer tw-rounded-lg tw-px-2 tw-py-1 tw-font-bold hover:tw-bg-gray-100"
          @click="logout()"
        >
          Uitloggen
        </div>
      </div>
    </div>
    <div
      @click="toggleMenu()"
      class="tw-fixed tw-left-0 tw-top-0 tw-z-[105] tw-block tw-h-full tw-w-full tw-cursor-pointer tw-bg-black tw-transition-all tw-delay-75 tw-duration-300 tw-ease-in-out lg:tw-hidden"
      :class="{
        'tw-opacity-60': globals.menuOpen,
        'tw-pointer-events-none tw-opacity-0': !globals.menuOpen,
      }"
    ></div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, onBeforeMount } from "vue";
import HeaderMenuIcons from "@/components/layout/HeaderMenuIcons.vue";
import { useGlobalStore } from "@/stores/global/global";
import useMenuItems from "@/components/includes/menuItems";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import store from "@/store/index.js";
import { useSettingsStore } from "store/modules/settingStore";
import { storeToRefs } from "pinia";

const router = useRouter();
const route = useRoute();
const locations = ref([]);
const { menuItems } = useMenuItems();
const schoolname = ref("");
const hovered = ref(false);

// Store
const globalStore = useGlobalStore();
const { globals } = storeToRefs(globalStore);

// Initialize setting store
const settingStore = useSettingsStore();
const { getUserSettings } = settingStore;

const checkMeetings = (checkOverview) => {
  if (checkOverview) {
    return route.fullPath.includes("meetings") && route.query.overview === "1";
  } else {
    return route.fullPath.includes("meetings") && !route.query.overview;
  }
};

onBeforeMount(async () => {
  await getUserSettings();
});

onMounted(async () => {
  locations.value = JSON.parse(localStorage.getItem("menu_locations"));
  schoolname.value = localStorage.getItem("school_name");
  analytics();

  window.addEventListener("resize", onResize);
});

router.beforeEach((to, from, next) => {
  globals.value.menuOpen = false;
  next();
});

watch(
  () => globals.value.menuOpen,
  (newVal) => {
    if (newVal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  },
);

const toggleMenu = () => {
  globals.value.menuOpen = !globals.value.menuOpen;
};

const logout = () => {
  localStorage.clear();
  window.location.reload();
};

const onResize = () => {
  globals.value.menuOpen = false;
  document.body.style.overflow = "auto";
};

const loginAsSchool = async (school) => {
  localStorage.setItem("school_id", school.id);
  localStorage.setItem("school_name", school.name);
  localStorage.setItem("user_name", school.username);

  let formData = new FormData();
  formData.set("school_id", school.id);

  return axios.post("/api/login-as-school", formData).then(function (res) {
    localStorage.removeItem("school_choices");
    store.dispatch("user/processResults", res.data);
  });
};

const analytics = () => {
  let school_name = localStorage.getItem("school_name");
  let role = "onbekende rol";
  let scriptExists = document.getElementById("gtag-script");
  if (!scriptExists) {
    let analyticsScript = document.createElement("script");
    analyticsScript.id = "gtag-script";
    analyticsScript.setAttribute(
      "src",
      "https://www.googletagmanager.com/gtag/js?id=G-J1WBE7PNP7",
    );
    // analyticsScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=UA-89827046-2')
    document.head.appendChild(analyticsScript);
  }

  let datalayerExists = document.getElementById("datalayer-script");
  if (datalayerExists) {
    let removeEl = document.getElementById("datalayer-script");
    removeEl.parentNode.removeChild(removeEl);
  }

  let user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    role = "Onbekende rol2";

    if (user.role.includes("webmaster")) {
      role = "webmaster";
    }

    if (user.role.includes("manager")) {
      role = "manager";
    }

    if (user.role.includes("organizer")) {
      role = "organizer";
    }

    if (user.role.includes("teamleader")) {
      role = "teamleader";
    }

    if (user.role.includes("teacher")) {
      role = "teacher";
    }

    if (user.role.includes("student")) {
      role = "student";
    }

    let scriptTag = document.createElement("script");
    scriptTag.id = "datalayer-script";
    let gaScript = document.createTextNode(
      "  window.dataLayer = window.dataLayer || [];\n" +
        "function gtag(){dataLayer.push(arguments);}\n" +
        "gtag('js', new Date());\n" +
        "gtag('config', 'G-J1WBE7PNP7', {'page_title': '" +
        route.meta.title +
        "', 'page_path': '" +
        route.path +
        "'});\n" +
        "gtag('set', 'user_properties', { 'Rol vd gebruiker' : '" +
        role +
        "','School naam' : '" +
        school_name +
        "'});\n",
    );
    scriptTag.appendChild(gaScript);
    document.head.appendChild(scriptTag);
  }
};
</script>

<style lang="scss">
.menu {
  &__link {
    @apply tw-cursor-pointer tw-border-b-2 tw-border-white tw-px-2 tw-py-3 hover:tw-underline [&.router-link-active]:tw-border-primary [&.router-link-active]:tw-bg-gray-100 [&.router-link-active]:tw-text-primary [&.router-link-active]:hover:tw-no-underline;
  }

  //&.subMenuLayout {
  //  @apply tw-border-b-gray-100;
  //}

  &.subMenuLayout & {
    &__link {
      @apply tw-border-b-transparent;
    }
  }

  &__sublink {
    @apply tw-block tw-cursor-pointer tw-rounded-lg tw-px-2 tw-py-1 hover:tw-underline [&.router-link-active]:tw-bg-gray-100 [&.router-link-active]:hover:tw-no-underline;
  }

  &__submenu {
    @apply tw-absolute tw--mx-4 tw-hidden tw-border-b-4 tw-bg-white tw-px-4 tw-py-4 tw-shadow group-hover:tw-block;
  }
}
.hamburger {
  @apply tw-relative tw-aspect-square tw-h-10 tw-shrink-0 tw-cursor-pointer tw-rounded-full tw-border tw-border-gray-400 tw-text-primary tw-transition-all hover:tw-bg-gray-100;

  span {
    @apply tw-absolute tw-left-1/2 tw-top-1/2 tw-h-px tw-w-4 tw--translate-x-1/2 tw--translate-y-1/2 tw-transform tw-bg-gray-400;

    transition: all 0.3s ease-in-out;

    &:nth-child(1) {
      @apply tw--translate-y-[4px] tw-transform;
    }

    &:nth-child(2) {
      @apply tw-translate-y-0 tw-transform;
    }

    &:nth-child(3) {
      @apply tw-translate-y-[4px] tw-transform;
    }
  }

  &.is-open {
    span {
      &:nth-child(1) {
        @apply tw-origin-center tw-translate-y-0 tw-rotate-45 tw-transform;
      }

      &:nth-child(2) {
        @apply tw-opacity-0;
      }

      &:nth-child(3) {
        @apply tw-origin-center tw-translate-y-0 tw--rotate-45 tw-transform;
      }
    }
    &:hover {
      span:nth-child(1) {
        @apply tw-rotate-[30deg];
      }

      span:nth-child(3) {
        @apply tw--rotate-[30deg];
      }
    }
  }
}
</style>
