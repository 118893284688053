<template>
  <c-input
    class="date-input"
    v-bind="$attrs"
    readonly
    v-model="model"
    hide-bottom-space
    :error="errorMessage !== undefined"
    :error-message="errorMessage"
    :rules="[
      (val) => !!val || 'Dit veld is verplicht',
      (val) => isValidDate(val) || 'Deze datum is niet geldig',
    ]"
  >
    <template v-slot:append>
      <q-icon size="xs" color="primary" name="fa-duotone fa-calendar-days" />
    </template>

    <q-popup-proxy transition-show="jump-down" transition-hide="jump-up">
      <q-date
        v-model="model"
        v-bind="$attrs"
        mask="DD-MM-YYYY"
        :options="dateOptions"
        minimal
        @update:model-value="closeDialog"
      >
        <div class="row items-center justify-end">
          <q-btn
            ref="closeButton"
            v-close-popup
            label="Sluiten"
            color="primary"
            flat
          />
        </div>
      </q-date>
    </q-popup-proxy>
  </c-input>
</template>

<script setup>
import { ref, watch } from "vue";
import { toDutchDateString, getDateStringFromQuasar } from "common/dateHelpers";

// Define the model
const model = defineModel({
  required: true,
  type: String,
  default: () => "",
});

// Set refs
const errorMessage = ref(undefined);
const closeButton = ref(null);

// Define the props
const props = defineProps({
  minDate: {
    type: String,
    required: false,
  },
  maxDate: {
    type: String,
    required: false,
  },
  changeToMinDate: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// This is ugly but it works as long as the close button is available in this component
// Quasar does not have a different way of closing the date dialog
// We trigger a click on the close button to call a directive
const closeDialog = (value) => {
  closeButton.value.$el.click();
};

// Filter the available date options
const dateOptions = (date) => {
  const minDate = props.minDate && getDateStringFromQuasar(props.minDate);
  const maxDate = props.maxDate && getDateStringFromQuasar(props.maxDate);

  return (!minDate || date >= minDate) && (!maxDate || date <= maxDate);
};

const isValidDate = (date) => {
  // Date values are not a valid date object -> DD-MM-YYYY
  const minDate = new Date(getDateStringFromQuasar(props.minDate));
  const maxDate = new Date(getDateStringFromQuasar(props.maxDate));
  const currentDate = new Date(getDateStringFromQuasar(date));

  // Form validation
  if (currentDate < minDate) {
    if (props.changeToMinDate) {
      model.value = toDutchDateString(minDate);
    }

    errorMessage.value = "Deze datum is niet geldig";

    return false;
  } else if (currentDate > maxDate) {
    if (props.changeToMinDate) {
      model.value = toDutchDateString(maxDate);
    }

    errorMessage.value = "Deze datum is niet geldig";

    return false;
  } else {
    errorMessage.value = undefined;

    return true;
  }
};

// Compare the min date and the current date
watch(
  [() => props.minDate, () => props.maxDate, () => model.value],
  ([minDate, __, model]) => {
    if (minDate === "") {
      return;
    }

    isValidDate(model);
  },
  { immediate: true },
);
</script>

<style lang="scss">
label.q-field.date-input {
  .q-field__control input.q-field__native,
  .q-field__append {
    @apply tw-cursor-pointer;
  }

  &.q-field--outlined.q-field--readonly {
    .q-field__control:before {
      @apply tw-border-solid;
    }
  }
}
</style>
