import axios from "axios";
import { acceptHMRUpdate, defineStore } from "pinia";
import { ref, computed } from "vue";
import useHelpers from "composables/useHelpers";

export const useStudentStore = defineStore("student", () => {
  // Helpers
  const { schoolId } = useHelpers();

  // Refs
  const students = ref([]);
  const schedulesByStudent = ref({});
  const student = ref(null);

  // Computed
  const mentorClasses = computed(() => {
    // Return a list with unique mentor classes
    return [
      ...new Set(students.value.flatMap(({ mentor_class }) => mentor_class)),
    ].sort();
  });

  const schedules = computed(() => {
    return [
      ...new Set(
        Object.values(schedulesByStudent.value).flatMap((schedule) => schedule),
      ),
    ].sort();
  });

  //   Actions
  const fetchStudents = async (doFetchSchedules = false) => {
    try {
      const { data } = await axios.get(`/api/school/${schoolId}/students`, {
        params: { withMentors: true },
      });

      data.map((student) => {
        const nameList = [
          ...(student.first_name ? [student.first_name] : []),
          ...(student.middle_name ? [student.middle_name] : []),
          ...(student.last_name ? [student.last_name] : []),
        ];
        student.formattedName = nameList.join(" ");
        student.schedules = [];
      });

      students.value = data;

      if (doFetchSchedules) {
        await fetchSchedules();
      }

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  const fetchSchedules = async () => {
    try {
      const { data } = await axios.get(
        `/api/school/${schoolId}/students-with-schedules`,
      );

      schedulesByStudent.value = data;

      Object.entries(data).forEach(([student_id, studentSchedules]) => {
        const foundStudent = students.value.find(({ id }) => id == student_id);

        if (foundStudent) {
          foundStudent.schedules = studentSchedules;
        }
      });
    } catch (error) {
      console.log("🚀 ~ fetchSchedules ~ error:", error);
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  const fetchStudent = async (id) => {
    try {
      const { data } = await axios.get(
        `/api/school/${schoolId}/students/${id}`,
      );

      student.value = data;

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  const createStudent = async (student, schoolId) => {
    try {
      const { data } = await axios.post(`/api/school/${schoolId}/students`, student);

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  const updateStudent = async (student) => {
    try {
      const { data } = await axios.put(`/api/students/${student.id}`, student);

      return Promise.resolve(data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  const getStudentNameById = (id) => {
    const student = students.value.find((student) => student.id === id);

    return student ? student.formattedName : "";
  };

  return {
    students,
    student,
    schedules,
    schedulesByStudent,
    mentorClasses,
    fetchStudents,
    fetchStudent,
    createStudent,
    updateStudent,
    fetchSchedules,
    getStudentNameById,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStudentStore, import.meta.hot));
}
