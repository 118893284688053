<template>
  <div class="tw-flex tw-flex-col tw-gap-2">
    <div
      class="tw-flex tw-flex-row tw-gap-x-4 tw-gap-y-2 lg:tw-mb-2"
      v-for="(item, index) in model"
      :key="index"
    >
      <div class="tw-shrink-1 tw-flex tw-items-center lg:tw-col-span-3">
        <div>van</div>
      </div>

      <div class="tw-flex-1">
        <c-input-date
          label="Startdatum"
          v-model="item.start_date"
          :min-date="minDate(index, 'start_date')"
          :change-to-min-date="props.changeToMinDate"
          :disable
        />
      </div>

      <div class="tw-flex tw-flex-1 lg:tw-col-span-2">
        <c-input-time
          class="tw-flex-1"
          label="Begintijd"
          v-model="item.start_time"
          :disable="disable || item.start_date === ''"
          :compare-time="shouldCompareTime(index, 'start_date')"
          :add-time-gap="index > 0"
          :min-time="startTime(index).min"
        />
      </div>

      <div class="tw-shrink-1 tw-flex tw-items-center lg:tw-col-span-3">
        <div>tot</div>
      </div>

      <div class="tw-flex-1 lg:tw-col-span-3">
        <c-input-date
          label="Einddatum"
          v-model="item.end_date"
          :disable="disable || item.start_date === '' || item.start_time === ''"
          :min-date="item.start_date"
          :change-to-min-date="props.changeToMinDate"
        />
      </div>

      <div class="tw-flex-1 lg:tw-col-span-2">
        <c-input-time
          class="tw-flex-1"
          label="Eindtijd"
          v-model="item.end_time"
          :disable="disable || item.end_date === ''"
          :compare-time="shouldCompareTime(index, 'end_date')"
          add-time-gap
          :min-time="endTime(index).min"
        />
      </div>

      <div
        class="tw-shrink-1 tw-flex tw-items-center tw-justify-center lg:tw-col-span-1"
      >
        <c-button-icon
          icon="close"
          color="negative"
          @click="model.splice(index, 1)"
        />
      </div>
    </div>

    <div class="tw-flex" v-if="showAddRowButton">
      <c-button-icon :disabled="!canAddNewRow" icon="add" @click="addRow()" />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { DEFAULTSTARTTIME, DEFAULTENDTIME } from "common/timeOptions";

// Define the model
const model = defineModel({
  required: true,
  type: Array,
  default: () => [],
});

const props = defineProps({
  minDate: {
    type: String,
    required: true,
  },
  minTime: {
    type: String,
    required: false,
    default: ({ minTime }) => {
      return minTime === "" || minTime == null ? DEFAULTSTARTTIME : minTime;
    },
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  changeToMinDate: {
    type: Boolean,
    required: false,
    default: false,
  },
  disable: {
    type: Boolean,
    default: false,
  },
});

const minDate = (index, key) => {
  const oppositeKey = key === "start_date" ? "end_date" : "start_date";

  if (index === 0) {
    return key === "start_date"
      ? props.minDate
      : model.value[index][oppositeKey];
  }

  return key === "start_date"
    ? model.value[index - 1][oppositeKey]
    : model.value[index][oppositeKey];
};

const shouldCompareTime = (index, key) => {
  if (!model.value[index][key]) {
    return false;
  }

  return minDate(index, key) === model.value[index][key];
};

// Available time options
const startTime = (index) => {
  if (index === 0) {
    return {
      min:
        props.minDate === model.value[index].start_date
          ? props.minTime
          : DEFAULTSTARTTIME,
      max: DEFAULTENDTIME,
    };
  }

  return {
    min:
      model.value[index].start_date === model.value[index - 1].end_date
        ? model.value[index - 1].end_time
        : DEFAULTSTARTTIME,
    max: DEFAULTENDTIME,
  };
};

const endTime = (index) => {
  return {
    min:
      model.value[index].start_date === model.value[index].end_date
        ? model.value[index].start_time
        : DEFAULTSTARTTIME,
    max: DEFAULTENDTIME,
  };
};

// Hide the add row button when multiple is false and there is already a row
const showAddRowButton = computed(() => {
  if (!props.multiple && model.value.length > 0) {
    return false;
  }

  return true;
});

// Object to add for a new row
const addRow = () => {
  if (!props.multiple && model.value.length > 0) {
    return;
  }

  model.value.push({
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
  });
};

// Check if all fields are filled
const canAddNewRow = computed(() => {
  if (!props.multiple && model.value.length > 0) {
    return;
  }

  return model.value.every((item) => {
    return Object.values(item).every((value) => value !== "");
  });
});
</script>
