<template>
  <router-link
    v-if="globals.schoolModules?.includes('plusdocument')"
    :to="{
      name: 'MyTasks',
    }"
    class="tw-relative tw-border-primary tw-px-2 tw-py-3 hover:tw-underline [&.router-link-active]:tw-border-b-2 [&.router-link-active]:tw-bg-gray-100 [&.router-link-active]:tw-text-primary [&.router-link-active]:hover:tw-no-underline"
    :class="{
      'tw--mr-1 tw-pr-5': globals.unreadTasksCount > 0,
      'router-link-active': router.name === 'TaskInfo',
    }"
  >
    Mijn Taken
    <div
      v-if="globals.unreadTasksCount > 0"
      class="tw-absolute tw-right-2 tw-top-1 tw-flex tw-h-3.5 tw-w-3.5 tw-items-center tw-justify-center tw-rounded-full tw-bg-red-600 tw-text-[8px] tw-text-white"
    >
      {{ globals.unreadTasksCount }}
    </div>
  </router-link>
</template>

<script setup>
import axios from "axios";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import useHelpers from "@/composables/useHelpers";
import { useGlobalStore } from "@/stores/global/global";
import { storeToRefs } from "pinia";

const { studentId } = useHelpers();
const router = useRoute();

const globalStore = useGlobalStore();
const { globals } = storeToRefs(globalStore);

onMounted(async () => {
  await getUnfinishedTasks();
});

const getUnfinishedTasks = async () => {
  return axios.get(`/api/student/${studentId}/portfolio-tasks`).then((res) => {
    globals.value.unreadTasksCount = res?.data?.uncompleted?.length ?? 0;
  });
};
</script>
