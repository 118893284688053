<template>
  <MainLayout>
    <template #headerSpace v-if="schoolSettings.transition_meeting">
      <SubMenu :menu-items="menuItems" />
    </template>

    <router-view />
  </MainLayout>
</template>
<script setup>
import SubMenu from "components/global/SubMenu.vue";
import { useGlobalStore } from "@/stores/global/global.js";
import { useSettingsStore } from "store/modules/settingStore.js";
import { storeToRefs } from "pinia";
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";

const globalStore = useGlobalStore();
const { globals } = storeToRefs(globalStore);
const { schoolModules } = globals.value;
const settingStore = useSettingsStore();
const { getSchoolSettings, schoolSettings } = settingStore;

const route = useRoute();

onMounted(() => {
  getSchoolSettings();
});

const menuItems = computed(() => [
  {
    name: "Leerlingbespreking",
    route: `${route.matched[0].name}StudentMeetings`,
    visible: schoolModules?.includes("feedback"),
  },
  {
    name: "Bevorderingsbespreking",
    route: `${route.matched[0].name}TransitionMeetings`,
    visible: schoolModules?.includes("feedback"),
  },
]);
</script>
