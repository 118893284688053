import axios from "axios";
import { defineStore } from "pinia";
import { useQuasar } from "quasar";
import { reactive, ref } from "vue";
import useHelpers from "composables/useHelpers";

export const useSettingsStore = defineStore("settings", () => {
  // Quasar
  const $q = useQuasar();

  // Refs
  const initialSchoolSettings = reactive({});
  const schoolSettings = reactive({});
  const restrictedSchoolSettings = reactive({});
  const userSettings = reactive({});
  const fetchingData = ref(false);
  const updatingData = ref(false);

  // Actions
  /**
   * Fetch the school settings by school id
   * @param {number} id
   * @param forceRefetch
   * @returns {any}
   */
  const getSchoolSettings = async (id, forceRefetch = false) => {
    id = id || useHelpers().schoolId;

    if (Object.keys(schoolSettings).length !== 0 && !forceRefetch) {
      return;
    }

    try {
      fetchingData.value = true;

      const {
        data: { data: schoolData },
      } = await axios.get(`/api/school/${id}/settings`);

      Object.assign(schoolSettings, schoolData);

      // Store the initial school settings before updating without Object reference
      Object.assign(
        initialSchoolSettings,
        JSON.parse(JSON.stringify(schoolData)),
      );

      return schoolData;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  const getRestrictedSchoolSettings = async (id, forceRefetch = false) => {
    id = id || useHelpers().schoolId;

    if (Object.keys(restrictedSchoolSettings).length !== 0 && !forceRefetch) {
      return;
    }

    try {
      fetchingData.value = true;

      const {
        data: { data: schoolData },
      } = await axios.get(`/api/schools/${id}`);

      Object.assign(restrictedSchoolSettings, schoolData);

      return schoolData;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  /**
   * Get the current authenticated user settings
   * @returns {any}
   */
  const getUserSettings = async () => {
    try {
      fetchingData.value = true;

      const { data } = await axios.get(`/api/get-user-settings`);

      Object.assign(userSettings, data);

      return userSettings;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  /**
   * Enable or disable the student check-in
   * @param {number} value
   * @returns {any}
   */
  const updateStudentCheckIn = async (value) => {
    try {
      updatingData.value = true;

      await axios.post(
        `/api/school/${schoolSettings.school_id}/update-student-check-in`,
        { student_check_in: value },
      );

      schoolSettings.student_check_in = value;

      // Update initialSchoolSettings as it's succesfully updated
      initialSchoolSettings.student_check_in = value;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        schoolSettings.student_check_in = value === 1 ? 0 : 1;

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Enable or disable the transition meetings
   * @param {number} value
   * @returns {any}
   */
  const updateTransitionMeeting = async (value) => {
    try {
      updatingData.value = true;

      await axios.post(
        `/api/school/${schoolSettings.school_id}/update-transition-meeting-setting`,
        { transition_meeting: value },
      );

      schoolSettings.transition_meeting = value;

      // Update initialSchoolSettings as it's succesfully updated
      initialSchoolSettings.transition_meeting = value;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        schoolSettings.transition_meeting = value === 1 ? 0 : 1;

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Set the actionplan version
   * @param {string} value
   * @returns {any}
   */
  const updateActionPlanVersion = async (value) => {
    updatingData.value = true;

    try {
      await axios.post(
        `/api/school/${schoolSettings.school_id}/update-actionplan-version`,
        {
          actionplan_version: value,
        },
      );

      schoolSettings.actionplan_version = value;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        schoolSettings.actionplan_version = value === "1.0" ? "2.0" : "1.0";

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * This will update the max available skills per activity setting
   * @returns {any}
   */
  const updateSkillLimitPerActivity = async () => {
    try {
      updatingData.value = true;

      let formData = new FormData();
      formData.set("skill_limit", schoolSettings.max_skills_per_activity);

      await axios.post(
        `/api/school/${schoolSettings.school_id}/update-skill-limit-per-activity`,
        formData,
      );

      // Update initialSchoolSettings as it's succesfully updated
      initialSchoolSettings.max_skills_per_activity =
        schoolSettings.max_skills_per_activity;

      $q.notify({
        message: `Aantal vaardigheden per activiteit aangepast naar ${schoolSettings.max_skills_per_activity}.`,
        color: "positive",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * This will update the email settings
   * @param {string} type
   * @param {string} value
   * @returns {any}
   */
  const updateEmailSettings = async (type, value) => {
    const formData = new FormData();
    formData.set("type", type);
    formData.set("value", value);

    try {
      updatingData.value = true;

      await axios.post(
        `/api/school/${schoolSettings.school_id}/email-settings`,
        formData,
      );

      return Promise.resolve();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    } finally {
      updatingData.value = false;
    }
  };

  return {
    schoolSettings,
    restrictedSchoolSettings,
    initialSchoolSettings,
    userSettings,
    fetchingData,
    updatingData,
    getSchoolSettings,
    getRestrictedSchoolSettings,
    getUserSettings,
    updateStudentCheckIn,
    updateTransitionMeeting,
    updateSkillLimitPerActivity,
    updateEmailSettings,
    updateActionPlanVersion,
  };
});
