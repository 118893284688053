<template>
  <router-link
    to="/notifications"
    class="tw-relative tw-border-primary tw-px-1 tw-py-3 hover:tw-underline [&.router-link-active]:tw-border-b-2 [&.router-link-active]:tw-bg-gray-100 [&.router-link-active]:tw-text-primary [&.router-link-active]:hover:tw-no-underline"
    :class="{
      'tw--mr-1 tw-pr-2.5':
        globals.unreadNotificationsCount > 0 && router.name !== 'notifications',
    }"
  >
    <q-icon name="fa-light fa-bell  fa-swap-opacity" size="20px" />
    <div
      v-if="
        globals.unreadNotificationsCount > 0 && router.name !== 'notifications'
      "
      class="tw-absolute tw-right-1 tw-top-1 tw-flex tw-h-3.5 tw-w-3.5 tw-items-center tw-justify-center tw-rounded-full tw-bg-red-600 tw-text-[8px] tw-text-white"
    >
      {{ globals.unreadNotificationsCount }}
    </div>
  </router-link>
</template>

<script setup>
import axios from "axios";
import { onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { useGlobalStore } from "@/stores/global/global";
import { storeToRefs } from "pinia";

// Router
const router = useRoute();

// Global store
const globalStore = useGlobalStore();
const { globals } = storeToRefs(globalStore);

onBeforeMount(async () => {
  await getUnreadNotifications();
});

const getUnreadNotifications = async () => {
  let id = JSON.parse(localStorage.getItem("user"))?.id;

  if (localStorage.getItem("employee_id")) {
    id = JSON.parse(localStorage.getItem("employee_id"));
  } else if (localStorage.getItem("student_user_id")) {
    id = JSON.parse(localStorage.getItem("student_user_id"));
  }

  if (!id) {
    return;
  }

  try {
    const { data } = await axios.get(`/api/user/${id}/notifications`);

    globals.value.unreadNotificationsCount = data.length;
  } catch (error) {
    console.log("🚀 ~ awaitaxios.get ~ error:", error);
  }
};
</script>
