/**
 * This will generate a date string in the format of YYYY/MM/DD
 * @param {any} date - The date to be formatted. Defaults to Date.now()
 * @returns {any} A date string in the format of YYYY/MM/DD
 */
export const getDateString = (date = Date.now()) => {
  const selectedDate = new Date(date);
  const [day, month, year] = [
    selectedDate.getDate().toString().padStart(2, "0"),
    (selectedDate.getMonth() + 1).toString().padStart(2, "0"),
    selectedDate.getFullYear(),
  ];

  return `${year}/${month}/${day}`;
};

/**
 * This will generate a date string from the format of YYYY-MM-DD
 * @param {any} date - The date to be formatted
 * @returns {any} A date string in the format of YYYY/MM/DD
 */
export const getDateStringFromQuasar = (date) => {
  if (!date || typeof date !== "string") {
    return;
  }

  const [day, month, year] = date.split("-");

  return getDateString(`${year}/${month}/${day}`);
};

/**
 * This will generate a date string in the format of DD-MM-YYYY
 * @param {any} date - The date to be formatted. Defaults to Date.now()
 * @returns {any} A date string in the format of DD-MM-YYYY
 */
export const toDutchDateString = (date = Date.now()) => {
  const selectedDate = new Date(date);
  const [day, month, year] = [
    selectedDate.getDate().toString().padStart(2, "0"),
    (selectedDate.getMonth() + 1).toString().padStart(2, "0"),
    selectedDate.getFullYear(),
  ];

  return `${day}-${month}-${year}`;
};

/**
 * This will return a date object from a dutch date and time string
 * @param {any} dateString - In the format: DD-MM-YYYY
 * @param {any} timeString = "00:00"
 * @returns {any}
 */
export const fromDutchToIntlDate = (dateString, timeString = "00:00") => {
  if (!dateString || typeof dateString !== "string") {
    return;
  }

  timeString = timeString || "00:00";

  // Date parts
  const dateParts = dateString.split("-");
  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;
  const year = parseInt(dateParts[2], 10);

  // Time parts
  const timeParts = timeString.split(":");
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);

  return new Date(year, month, day, hours, minutes);
};

/**
 * Check if two dates are on the same day
 * @param {any} a - The first date to compare, b - The second date to compare
 * @returns {any}
 */
export const isSameDay = (a = Date.now(), b = Date.now()) => {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
};
