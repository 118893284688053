<template>
  <div class="tw-bg-primary tw-text-left tw-text-white">
    <div
      class="tw-container tw-mx-auto tw-items-end tw-justify-between tw-gap-x-4 tw-px-2 tw-py-4 md:tw-flex"
    >
      <div class="tw-w-3/4 md:tw-w-4/12">
        <div>
          <img
            class="tw-w-[150px]"
            src="/images/logo_white_no_tagline.svg"
            alt="Logo Catwise"
          />
        </div>
      </div>
      <div
        class="tw-mt-5 tw-flex tw-w-3/4 tw-flex-col tw-gap-y-1 tw-text-xs md:tw-mt-0 md:tw-w-auto"
      >
        <div>
          <div v-if="currentShortage && user.role[0] === 'webmaster'">
            Ingelogd als <span class="tw-font-bold">webmaster</span> als
            <span class="tw-font-bold">medewerker</span>:
            {{ currentShortage }}
          </div>
        </div>
        <div>
          Je bent ingelogd als {{ userName }} ({{ roleName(user.role[0]) }}) bij
          {{ schoolName }} (#{{ schoolId }})
        </div>
      </div>
    </div>
  </div>
  <div class="tw-w-full tw-bg-secondary tw-py-1 tw-text-white md:tw-text-right">
    <div class="tw-container tw-mx-auto tw-px-2">
      <router-link
        class="tw-text-xs"
        :to="
          checkRole('student')
            ? { name: 'StudentForm' }
            : { name: 'FeedbackForm' }
        "
        >Bug melden?</router-link
      >
    </div>
  </div>
  <NpsModal v-if="showNps" @close-modal="showNps = false" />
</template>

<script setup>
import useHelpers from "@/composables/useHelpers";
import NpsModal from "../modals/nps/NpsModal.vue";
import { ref, onMounted } from "vue";

const showNps = ref(false);

onMounted(() => {
  showNps.value = localStorage.getItem("show_nps") !== "false";
});

const {
  userName,
  schoolId,
  schoolName,
  user,
  currentShortage,
  roleName,
  checkRole,
} = useHelpers();
</script>
