import useHelpers from "composables/useHelpers";
const { mappedTerms } = useHelpers();

const routes = [
  {
    path: "/manage",
    name: "manage",
    component: () => import("pages/manage/Manage.vue"),
    meta: {
      transition: "fade",
      breadcrumb: "Beheer",
      title: "Beheer",
      layout: "MainLayout",
      roleRequired: ["webmaster", "manager", "organizer"],
    },
    children: [
      {
        path: "meetings",
        component: () => import("views/EmptyView.vue"),
        meta: {
          breadcrumb: "Besprekingen",
          transition: "fade",
          title: "Besprekingen",
        },
        children: [
          {
            path: "",
            name: "meetings",
            component: () => import("pages/manage/Meetings.vue"),
          },
          {
            path: "add-student-meeting",
            component: () => import("views/EmptyView.vue"),
            meta: {
              transition: "fadee",
              title: "Leerlingbespreking toevoegen",
              roleRequired: ["webmaster", "manager", "organizer"],
            },
            children: [
              {
                path: "",
                name: "addStudentMeeting",
                component: () =>
                  import("pages/manage/meeting/AddStudentMeeting.vue"),
                meta: {
                  hideSubmenu: true,
                },
              },
            ],
          },
          {
            path: "add-transition-meeting",
            component: () => import("views/EmptyView.vue"),
            meta: {
              transition: "fadee",
              title: "Bevorderingsbespreking toevoegen",
              roleRequired: ["webmaster", "manager", "organizer"],
            },
            children: [
              {
                path: "",
                name: "addTransitionMeeting",
                component: () =>
                  import("pages/manage/meeting/AddTransitionMeeting.vue"),
                meta: {
                  hideSubmenu: true,
                },
              },
            ],
          },
          {
            path: "transition-meeting",
            component: () => import("views/EmptyView.vue"),
            meta: {
              transition: "fadee",
              title: "Bevorderingsbespreking bewerken",
              roleRequired: ["webmaster", "manager", "organizer", "teamleader"],
            },
            children: [
              {
                path: ":id",
                name: "transitionMeeting",
                component: () =>
                  import("pages/manage/meeting/TransitionMeeting.vue"),
                meta: {
                  hideSubmenu: true,
                },
              },
            ],
          },
          {
            path: "student-meeting",
            component: () => import("views/EmptyView.vue"),
            meta: {
              transition: "fadee",
              title: "Leerlingbespreking bewerken",
              roleRequired: ["webmaster", "manager", "organizer", "teamleader"],
            },
            children: [
              {
                path: ":id",
                name: "studentMeeting",
                component: () =>
                  import("pages/manage/meeting/UpdateMeeting.vue"),
                meta: {
                  hideSubmenu: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: "settings",
        name: "settings",
        component: () => import("pages/manage/Settings.vue"),
        meta: {
          breadcrumb: "Schoolinstellingen",
          transition: "fade",
          title: "Schoolinstellingen",
          layout: "MainLayout",
        },
      },
      {
        path: "data",
        name: "data",
        component: () => import("pages/manage/Data.vue"),
        meta: {
          breadcrumb: "Data",
          transition: "fade",
          title: "Data beheren",
        },
        children: [
          {
            path: "view-students",
            name: "ViewStudents",
            component: () => import("pages/manage/data/ViewStudents.vue"),
            meta: {
              breadcrumb: `${mappedTerms().students} beheren`,
              transition: "fade",
              title: `${mappedTerms().students[0].toUpperCase()}${mappedTerms().students.slice(
                1,
              )} beheren`,
            },
          },
          {
            path: "add-student",
            name: "addStudent",
            component: () => import("pages/manage/data/AddOrUpdateStudent.vue"),
            meta: {
              title: `${mappedTerms().students[0].toUpperCase()}${mappedTerms().students.slice(
                1,
              )} toevoegen`,
              roleRequired: ["webmaster", "manager"],
            },
          },
          {
            path: "add-schedule",
            name: "AddSchedule",
            component: () =>
              import("pages/manage/data/AddOrUpdateSchedule.vue"),
            meta: {
              title: "Lesgroep toevoegen",
              roleRequired: ["webmaster", "manager"],
            },
          },
          {
            path: "upload-profile-picture",
            name: "UploadProfilePicture",
            component: () =>
              import("pages/manage/data/UploadProfilePicture.vue"),
            meta: {
              breadcrumb: "Pasfoto",
              transition: "fade",
              title: "Profielfoto",
            },
          },
          {
            path: "import-students",
            name: "ImportStudents",
            component: () =>
              import("pages/manage/data/import/ImportStudents.vue"),
            meta: {
              transition: "fade",
              title: `${mappedTerms().students[0].toUpperCase()}${mappedTerms().students.slice(
                1,
              )} importeren`,
            },
          },
          {
            path: "remove-students",
            name: "RemoveStudents",
            component: () =>
              import("pages/manage/data/import/RemoveStudents.vue"),
            meta: {
              transition: "fade",
              title: `${mappedTerms().students[0].toUpperCase()}${mappedTerms().students.slice(
                1,
              )} verwijderen`,
            },
          },
          {
            path: "transfer-students",
            name: "TransferStudents",
            component: () => import("pages/manage/data/TransferStudents.vue"),
            meta: {
              transition: "fade",
              title: `${mappedTerms().students[0].toUpperCase()}${mappedTerms().students.slice(
                1,
              )} verplaatsen`,
            },
          },
          {
            path: "view-employees",
            name: "ViewEmployees",
            component: () => import("pages/manage/data/ViewEmployees.vue"),
            meta: {
              breadcrumb: "Medewerkers beheren",
              transition: "fade",
              title: "Medewerkers beheren",
            },
          },
          {
            path: "import-employees",
            name: "ImportEmployees",
            component: () =>
              import("pages/manage/data/import/ImportEmployees.vue"),
            meta: {
              breadcrumb: "Medewerkers import",
              transition: "fade",
              title: "Medewerkers importeren",
            },
          },
          {
            path: "view-schedules",
            name: "ViewSchedules",
            component: () => import("pages/manage/data/ViewSchedules.vue"),
            meta: {
              breadcrumb: "Vakkenpaket beheren",
              transition: "fade",
              title: "Vakkenpakket beheren",
            },
          },
          {
            path: "import-schedules",
            name: "ImportSchedules",
            component: () =>
              import("pages/manage/data/import/ImportSchedules.vue"),
            meta: {
              breadcrumb: "Vakkenpakket import",
              transition: "fade",
              title: "Vakkenpakket importeren",
            },
          },
          {
            path: "view-subjects",
            name: "ViewSubjects",
            component: () => import("pages/manage/data/ViewSubjects.vue"),
            meta: {
              breadcrumb: "Vakken beheren",
              transition: "fade",
              title: "Vakken beheren",
            },
          },
          {
            path: "view-grades",
            name: "ViewGrades",
            component: () => import("pages/manage/data/ViewGrades.vue"),
            meta: {
              breadcrumb: "Cijfers inzien",
              transition: "fade",
              title: "Cijfers inzien",
            },
          },
        ],
      },
      {
        path: "connect",
        name: "connect",
        component: () => import("pages/manage/Connect.vue"),
        meta: {
          breadcrumb: "Koppeling",
          transition: "fade",
          title: "Koppeling beheren",
        },
      },
      {
        path: "schoolsynclog",
        name: "schoolsynclog",
        component: () => import("pages/manage/SchoolSyncLog.vue"),
        meta: {
          breadcrumb: "Sync log",
          transition: "fade",
          title: "schoolsynclog",
        },
      },
      {
        path: "schoollog",
        name: "schoollog",
        component: () => import("pages/manage/SchoolLog.vue"),
        meta: {
          breadcrumb: "Log",
          transition: "fade",
          title: "schoollog",
        },
      },
    ],
  },
];

export default routes;
