<template>
  <StatusBar />
  <component v-if="initializing === false" :is="layout">
    <router-view v-slot="{ Component, route }">
      <component :is="Component" :key="route.path" />
    </router-view>
  </component>
</template>

<script setup>
import axios from "axios";
import { computed, onBeforeMount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import StatusBar from "components/layout/StatusBar.vue";

// Router
const router = useRouter();
const route = useRoute();

// Refs
const initializing = ref(true);

const layout = computed(() => {
  return route?.meta?.layout || "MainLayout";
});

// Methods
const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

onMounted(async () => {
  await router.isReady();

  initializing.value = false;
});

onBeforeMount(async () => {
  let user = localStorage.getItem("user");

  // TODO: Herschrijven
  if (user) {
    user = JSON.parse(user);
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("user_token");
    axios.defaults.headers.common["TwoFactor"] = getCookie(
      "TwoFactor" + user.id,
    );
  }
});
</script>
