const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("pages/auth/Login.vue"),
    meta: { transition: "fade", title: "Inloggen", layout: "AuthLayout" },
  },
  {
    path: "/inloggen",
    name: "Inloggen",
    component: () => import("pages/auth/LoginSso.vue"),
    meta: { transition: "fade", title: "Inloggen", layout: "AuthLayout" },
  },
  {
    path: "/2fa",
    name: "Tweestapsverificatie",
    component: () => import("pages/auth/TwoFactorAuthentication.vue"),
    meta: {
      transition: "fade",
      title: "Tweestapsverificatie",
      layout: "AuthLayout",
    },
  },
  {
    path: "/school-login-choice",
    name: "SchoolLoginChoice",
    component: () => import("pages/auth/SchoolLoginChoice.vue"),
    meta: { transition: "fade", title: "School kiezen", layout: "AuthLayout" },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("pages/auth/Login.vue"),
    meta: {
      transition: "fade",
      title: "Wachtwoord vergeten",
      layout: "AuthLayout",
    },
  },
];

export default routes;
