import { computed, ref } from "vue";
import useHelpers from "@/composables/useHelpers";
import { useGlobalStore } from "@/stores/global/global";
import { storeToRefs } from "pinia";

export default function useMenuItems() {
  const { checkRole } = useHelpers();
  const globalStore = useGlobalStore();
  const { globals } = storeToRefs(globalStore);

  const menuItems = computed(() => {
    const routes = [
      {
        name: "Webmaster",
        to: "/webmaster",
        visible: checkRole("webmaster"),
        subMenu: [
          {
            name: "Scholen beheren",
            to: "/webmaster/schools",
            visible: true,
          },
          {
            name: "Inloggen als school",
            to: "/webmaster/login-as-school",
            visible: true,
          },
          {
            name: "School statistieken",
            to: "/webmaster/school-stats",
            visible: true,
          },
          {
            name: "Inloggen als personeel",
            to: "/webmaster/login-as-employee",
            visible: true,
          },
          {
            name: "Inloggen als student",
            to: "/webmaster/login-as-student",
            visible: true,
          },
          {
            name: "NPS Data",
            to: "/webmaster/nps-data",
            visible: true,
          },
          {
            name: "Invulsuggesties",
            to: "/webmaster/tips",
            visible: true,
          },
          {
            name: "Vak synoniemen",
            to: "/webmaster/synonyms",
            visible: true,
          },
          {
            name: "Cijfers importeren",
            to: "/webmaster/import-grades",
            visible: true,
          },
          {
            name: "E-mail export",
            to: "/webmaster/email-export",
            visible: true,
          },
          {
            name: "Leerlingen overzetten",
            to: "/manage/data/transfer-students",
            visible: true,
          },
          {
            name: "Synchronisatie logs",
            to: "/webmaster/sync-logs",
            visible: true,
          },
          {
            name: "Log",
            to: "/webmaster/logs",
            visible: true,
          },
        ],
      },
      {
        name: "Beheer",
        to: globals.value.schoolModules.includes("feedback")
          ? "/manage/meetings/"
          : "/manage/",
        visible:
          checkRole("webmaster") ||
          checkRole("organizer") ||
          checkRole("manager"),
      },
      {
        name: "Overzichten",
        to: "/overview",
        visible:
          globals.value.schoolModules?.includes("feedback") &&
          (checkRole("webmaster") ||
            checkRole("organizer") ||
            checkRole("manager") ||
            checkRole("teamleader")),
        subMenu: [
          {
            name: "Leerlingen",
            to: "/overview",
            visible: true,
          },
          {
            name: "Besprekingen",
            to: "/overview/meetings",
            visible: true,
          },
        ],
      },
      {
        name: "Mijn besprekingen",
        to: "/my-meetings",
        visible:
          globals.value.schoolModules?.includes("feedback") &&
          (checkRole("webmaster") ||
            checkRole("organizer") ||
            checkRole("manager") ||
            checkRole("teamleader") ||
            checkRole("decaan") ||
            checkRole("teacher")),
      },
      {
        name: "Mijn mentorgroep",
        to: {
          name: "myStudents",
        },
        visible:
          globals.value.schoolModules?.includes("feedback") &&
          (checkRole("webmaster") ||
            checkRole("organizer") ||
            checkRole("manager") ||
            checkRole("teamleader") ||
            checkRole("decaan") ||
            checkRole("teacher")),
      },
      {
        name: "Mijn lesgroepen",
        to: {
          name: "myLessongroups",
        },
        visible:
          globals.value.schoolModules?.includes("feedback") &&
          (checkRole("webmaster") ||
            checkRole("organizer") ||
            checkRole("manager") ||
            checkRole("teamleader") ||
            checkRole("decaan") ||
            checkRole("teacher")),
      },
      {
        name: "Taken",
        to: "/tasks",
        visible:
          globals.value.schoolModules?.includes("plusdocument") &&
          (checkRole("decaan") ||
            checkRole("webmaster") ||
            checkRole("organizer") ||
            checkRole("manager")),
      },
      {
        name: "Mijn feedback",
        to: "/my-ratings",
        visible:
          globals.value.schoolModules?.includes("feedback") &&
          checkRole("student"),
      },
      {
        name: "Portfolio",
        to: "/portfolio",
        visible:
          globals.value.schoolModules?.includes("plusdocument") &&
          checkRole("student"),
      },
    ];

    return routes.filter(({ visible }) => visible);
  });

  return { menuItems };
}
