<template>
  <q-option-group v-bind="$attrs" :size type="radio" :class="classes">
    <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </q-option-group>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  size: {
    type: String,
    default: "xs",
  },
  borderless: {
    type: Boolean,
    default: false,
  },
});

const classes = computed(() => {
  return [...(props.borderless ? ["borderless"] : [])];
});
</script>

<style lang="scss">
.q-option-group {
  @apply tw-flex;

  &:not([class*="tw-gap"]) {
    @apply tw-gap-4;
  }

  &:not(.q-option-group--inline) {
    @apply tw-flex-col;
  }

  &.q-option-group--inline {
    @apply tw-m-0;

    > div {
      @apply tw-m-0 tw-flex-1;
    }
  }
}

div.q-option-group {
  &.borderless {
    div.q-radio {
      border: none;
    }
  }
}

div.q-radio {
  display: flex !important;
  border: 1px solid $grey;
  border-radius: $generic-border-radius;
  transition: border $generic-hover-transition;
  padding-right: 4px;

  &:hover {
    border: 1px solid $primary;
  }
}
</style>
