<template>
  <q-btn
    unelevated
    color="primary"
    v-bind="$attrs"
    size="sm"
    padding="8px 10px"
  >
    <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </q-btn>
</template>

<style lang="scss">
.q-btn {
  text-transform: none !important;

  &--outline {
    &:before {
      border: 1px solid $grey;
    }
  }
}
</style>
