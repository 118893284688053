import useHelpers from "composables/useHelpers.js";

const { mappedTerms } = useHelpers();

const routes = [
  {
    name: "myStudents",
    path: "/my-students",
    redirect: {
      name: "myStudentsStudentMeetings",
    },
    meta: {
      transition: "fade",
      title: `Mijn ${mappedTerms().students}`,
      role: "teacher",
      layout: "subMenuLayout",
      roleRequired: [
        "webmaster",
        "manager",
        "organizer",
        "teamleader",
        "decaan",
        "teacher",
      ],
    },
    children: [
      {
        name: "myStudentsStudentMeetings",
        path: "meetings/:mentor?/:id?",
        component: () => import("pages/my-students/StudentMeetings.vue"),
        meta: {
          transition: "fade",
          title: `Mijn ${mappedTerms().students}`,
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
      },
      {
        name: "myStudentsTransitionMeetings",
        path: "transition-meetings",
        component: () => import("pages/my-students/TransitionMeetings.vue"),
        meta: {
          transition: "fade",
          title: "Mijn bevorderingsbesprekingen",
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
      },
    ],
  },
];

export default routes;
