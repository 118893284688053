<template>
  <q-input
    outlined
    v-model="model"
    v-bind="$attrs"
    :class="[classes, requiredAndEmpty, _showPlaceholderOnFocus]"
    class="tw-relative tw-overflow-hidden"
  >
    <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>

    <div
      class="custom-input__check tw-absolute -tw-right-[12px] tw-top-0 tw-flex tw-h-[24px] tw-w-[24px] tw-items-center tw-justify-center tw-rounded-bl tw-rounded-tr tw-p-1"
      :class="feedbackIconClasses"
    >
      <q-icon :name="feedbackIcon" class="custom-input__check__icon" />
    </div>
  </q-input>
</template>

<script setup>
import { computed, watch, ref } from "vue";
import { debounce } from "quasar";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "~/tailwind.config.js";

// Define the model
const model = defineModel({
  required: true,
  type: [String, null],
  default: () => "",
});

// Define props
const props = defineProps({
  feedback: {
    type: Object,
    default: {},
  },
  labelSize: {
    type: String,
    default: "base",
    validator: (value) => {
      const {
        theme: { fontSize },
      } = resolveConfig(tailwindConfig);

      return Object.keys(fontSize).includes(value);
    },
  },
  height: {
    type: String,
    default: "default",
    validator: (value) => {
      return ["xs", "sm", "md", "lg", "default"].includes(value);
    },
  },
  showPlaceholderOnFocus: {
    type: Boolean,
    default: false,
  },
});

// Initialize the tailwindConfig file
const { theme } = resolveConfig(tailwindConfig);

// Refs
const currentFeedback = ref({});

// Return the correct icon based on the feedbackType
const feedbackIcon = computed(() => {
  const icon = currentFeedback.value.type === "error" ? "fa-xmark" : "fa-check";

  return `fa-solid ${icon}`;
});

// Add the required class if the input is required and empty
const requiredAndEmpty = computed(() => {
  if (props.required && model.value === "") {
    return "required";
  }
  return false;
});

const _showPlaceholderOnFocus = computed(() => {
  return props.showPlaceholderOnFocus ? "show-placeholder-on-focus" : false;
});

const classes = computed(() => {
  if (Object.keys(currentFeedback.value).length) {
    return currentFeedback.value?.type === "error"
      ? "feedback-error"
      : "feedback-success";
  }

  return "";
});

// Conditionally add classes based on the feedback
const feedbackIconClasses = computed(() => {
  return [
    ...(currentFeedback.value.type ? ["is-active"] : []),
    ...(currentFeedback.value.type === "error" ? ["tw-bg-red-500"] : []),
    ...(currentFeedback.value.type === "success" ? ["tw-bg-green-500"] : []),
  ];
});

// Return the scss variable specified in our tailwind config
// based on the labelSize property
const labelClass = computed(() => {
  const { fontSize } = theme;

  return fontSize?.[props.labelSize]
    ? fontSize[props.labelSize][0]
    : fontSize["base"][0];
});

// Return the height of the textarea
const inputHeight = computed(() => {
  switch (props.height) {
    case "xs":
      return "18px";
    case "sm":
      return "46px";
    case "md":
      return "56px";
    case "lg":
      return "200px";
    default:
      return "70px";
  }
});

// Remove the errors from the input
const removeFeedback = debounce(() => {
  if (currentFeedback.value.type === "error") {
    return;
  }

  currentFeedback.value = {};
}, 3000);

// Watch errors
watch(
  () => props.feedback,
  (feedback) => {
    currentFeedback.value = feedback;

    removeFeedback();
  },
);
</script>

<style lang="scss">
label.q-field {
  &.q-textarea {
    textarea.q-field__native {
      min-height: v-bind(inputHeight);
    }
  }

  .q-field {
    &__label {
      font-size: v-bind(labelClass);
    }
  }

  &.feedback-success {
    .q-field__control {
      @apply tw-bg-green-50;

      &::after {
        @apply tw-border-green-500 focus:tw-border-green-500;
      }
    }
  }

  &.feedback-error {
    .q-field__control {
      @apply tw-bg-red-50;

      &::after {
        @apply tw-border-red-500 focus:tw-bg-red-500;
      }
    }
  }

  // Don't show the check icon if the input is in an error state
  &.q-field--error {
    .custom-input__check {
      display: none;
    }

    .q-field__control {
      &::after {
        @apply tw-border-[currentColor];
      }
    }
  }

  .custom-input__check {
    @apply tw-transition-all tw-duration-200;
    transform: translate3d(102%, -102%, 0);

    &.is-active {
      transform: translate3d(0, 0, 0);
    }

    &__icon {
      @apply tw-text-white;
    }
  }

  .q-field__control-container input[required] + .q-field__label:after {
    content: " *";
  }

  &.q-field--error {
    .q-field__control {
      @apply tw-bg-red-50;
    }
  }

  &.show-placeholder-on-focus {
    .q-field__native::placeholder {
      opacity: 0;
    }

    .q-field__native:focus::placeholder {
      opacity: 0.7;
    }
  }
}
</style>
