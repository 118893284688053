<template>
  <div
    @click="scrollToTop"
    class="backtotop tw-group tw-fixed tw-bottom-4 tw-right-4 tw-z-[999] tw-cursor-pointer tw-rounded-lg tw-bg-primary tw-p-2 tw-text-white tw-transition-all hover:tw-bg-primary-900 md:tw-p-4"
    :class="isVisible ? 'visible' : ''"
  >
    <q-icon name="fa-solid fa-chevron-up" />
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";

const isVisible = ref(false);

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

onMounted(() => {
  window.addEventListener("scroll", () => {
    const scrollHeight = window.scrollY;
    isVisible.value = scrollHeight > 1000;
  });
});
</script>

<style lang="scss">
.backtotop {
  @apply tw-pointer-events-none tw-translate-y-1/2 tw-opacity-0;
  transition: opacity 0.3s ease-in-out;

  &.visible {
    @apply tw-pointer-events-auto tw-translate-y-0 tw-opacity-75;
  }
}
</style>
