import { createRouter, createWebHistory } from "vue-router";
import useHelpers from "@/composables/useHelpers";
import { useGlobalStore } from "@/stores/global/global";
import { storeToRefs } from "pinia";

// Import routes from modules
import modules from "./modules";

const { mappedTerms, schoolId, currentShortage, checkRole } = useHelpers();

// TODO: Change all the route names to the lowercase
// TODO: Base all route changes on the route name not on the path
// TODO: Define all routes with children in separate modules to keep the files clean
const routes = [
  {
    path: "/",
    name: "Start",
    component: () => import("pages/Startpage.vue"),
    meta: {
      transition: "fade",
      title: `${mappedTerms().environment}`,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("pages/info/Settings.vue"),
    meta: { transition: "fade", title: "Instellingen" },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("pages/info/Notifications.vue"),
    meta: { transition: "fade", title: "Notificaties" },
  },
  {
    path: "/feedback-formulier",
    name: "FeedbackForm",
    component: () => import("pages/info/FeedbackForm.vue"),
    meta: { transition: "fade", title: "Feedback formulier" },
  },
  {
    path: "/student-form",
    name: "StudentForm",
    component: () => import("pages/info/StudentForm.vue"),
    meta: { transition: "fade", title: "Feedback formulier" },
  },
  {
    path: "/help-formulier",
    name: "HelpForm",
    component: () => import("pages/info/HelpForm.vue"),
    meta: { transition: "fade", title: "Help formulier" },
  },
  {
    path: "/my-meetings",
    component: () => import("pages/MyMeetings.vue"),
    children: [
      {
        path: "",
        name: "MyMeetings",
        component: () => import("pages/MyMeetings.vue"),
        meta: {
          transition: "fade",
          title: "Mijn besprekingen",
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
        },
      },
      {
        path: ":teacherShortage",
        name: "MyMeetingsId",
        component: () => import("pages/MyMeetings.vue"),
        meta: {
          transition: "fade",
          title: "Taak ",
          roleRequired: ["webmaster", "manager", "organizer", "teamleader"],
        },
      },
    ],
  },
  {
    path: "/tasks",
    children: [
      {
        path: "",
        name: "Tasks",
        component: () => import("pages/manage/tasks/Tasks.vue"),
        meta: {
          transition: "fade",
          title: "Taken",
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
          ],
        },
      },
      {
        path: ":id",
        name: "TaskInfo",
        component: () => import("pages/manage/tasks/Task.vue"),
        meta: {
          transition: "fade",
          title: "Taak ",
          roleRequired: [
            "webmaster",
            "manager",
            "decaan",
            "organizer",
            "teamleader",
            "student",
          ],
        },
      },
      {
        path: "my-tasks",
        name: "MyTasks",
        component: () => import("pages/student/MyTasks.vue"),
        meta: {
          transition: "fade",
          title: "Mijn taken",
          roleRequired: [
            "webmaster",
            "manager",
            "decaan",
            "organizer",
            "teamleader",
            "student",
          ],
        },
      },
    ],
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: () => import("pages/student/Portfolio.vue"),
    meta: {
      transition: "fade",
      title: "Portfolio ",
      roleRequired: [
        "webmaster",
        "manager",
        "organizer",
        "teamleader",
        "student",
      ],
    },
  },
  {
    path: "/my-ratings",
    name: "MyRatings",
    component: () => import("pages/student/MyRatings.vue"),
    meta: {
      transition: "fade",
      title: "Mijn feedback",
      roleRequired: [
        "webmaster",
        "manager",
        "organizer",
        "teamleader",
        "teacher",
        "student",
      ],
    },
  },
  {
    path: "/overview",
    meta: {
      transition: "fade",
      title: "Overzichten",
      roleRequired: ["webmaster", "manager", "organizer", "teamleader"],
    },
    children: [
      {
        path: "",
        name: "Overview",
        component: () => import("pages/overview/OverviewStudents.vue"),
        meta: {
          transition: "fade",
          title: "Overzichten",
          roleRequired: ["webmaster", "manager", "organizer", "teamleader"],
        },
      },
      {
        path: "meetings",
        name: "Overview-meetings",
        component: () => import("pages/overview/OverviewMeetings.vue"),
        meta: {
          transition: "fade",
          title: "Overzichten",
          roleRequired: ["webmaster", "manager", "organizer", "teamleader"],
        },
      },
    ],
  },
  {
    path: "/add-school",
    name: "AddSchool",
    component: () => import("pages/admin/AddOrUpdateSchool.vue"),
    meta: {
      title: "School toevoegen",
      roleRequired: ["webmaster"],
    },
  },
  {
    path: "/edit-school/:id",
    name: "EditSchool",
    component: () => import("pages/admin/AddOrUpdateSchool.vue"),
    meta: { title: "School beheren", roleRequired: ["webmaster"] },
  },
  {
    path: "/edit-student/:id",
    name: "EditStudent",
    component: () => import("pages/manage/data/AddOrUpdateStudent.vue"),
    meta: {
      title: `${mappedTerms().students[0].toUpperCase()}${mappedTerms().students.slice(
        1,
      )} beheren`,
      roleRequired: ["webmaster", "manager"],
    },
  },
  {
    path: "/add-employee",
    name: "AddEmployee",
    component: () => import("pages/manage/data/AddOrUpdateEmployee.vue"),
    meta: {
      title: "Medewerker toevoegen",
      roleRequired: ["webmaster", "manager"],
    },
  },
  {
    path: "/edit-employee/:id",
    name: "EditEmployee",
    component: () => import("pages/manage/data/AddOrUpdateEmployee.vue"),
    meta: {
      title: "Medewerker beheren",
      roleRequired: ["webmaster", "manager"],
    },
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import("pages/Unauthorized.vue"),
    meta: {
      transition: "fade",
      title: "403 Oeps, geen toegang...",
      layout: "MainLayout",
    },
  },
  {
    // Error page
    path: "/404",
    name: "error",
    component: () => import("pages/Error.vue"),
    meta: { transition: "fade", title: "404 Pagina niet gevonden" },
  },
  {
    // Catch all route
    path: "/:pathMatch(.*)*",
    name: "404",
    redirect: { name: "error" },
  },
];

modules.forEach((module) => {
  routes.push(module);
});

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  let token = localStorage.getItem("user_token");
  let ssoEnabled = localStorage.getItem("validate_2fa");
  let schoolChoices = localStorage.getItem("school_choices");

  // Not authenticated -> Go to login
  if (token === null && to.name !== "Login") {
    return { name: "Login" };
  }

  // Authenticated and school choices -> Go to school login choice
  if (
    to.name !== "SchoolLoginChoice" &&
    token !== null &&
    schoolChoices !== null
  ) {
    return { name: "SchoolLoginChoice" };
  }

  // Authenticated and school choice is set -> Go to start
  if (to.name === "SchoolLoginChoice" && schoolChoices === null) {
    return { name: "Start" };
  }

  // Authenticated and 2fa enabled -> Go to 2fa
  if (
    to.name !== "Tweestapsverificatie" &&
    token !== null &&
    ssoEnabled !== null
  ) {
    return { name: "Tweestapsverificatie" };
  }

  // Authenticated -> Go to start
  if (to.name === "Login" && token !== null) {
    return { name: "Start" };
  }

  // For webmaster, check if currentShortage is set, otherwis redirect to login as employee.
  if (
    (to.name === "MyMeetings" ||
      to.name === "MyLessonGroups" ||
      to.matched[0].name === "myStudents" ||
      to.matched[0].name === "myLessongroups") &&
    currentShortage === null &&
    checkRole("webmaster")
  ) {
    return {
      name: "LoginAsEmployee",
    };
  }

  // Get modules
  if (schoolId !== null && token !== null && ssoEnabled === null) {
    // TODO: Put this inside the components, this is a heavy load on every route change
    const globalStore = useGlobalStore();
    const { getSchoolModules, getSchool } = globalStore;
    const { globals, hasFetchedSchoolData, hasFetchedModuleData } =
      storeToRefs(globalStore);

    if (!hasFetchedSchoolData.value) {
      await getSchool();
    }

    if (!hasFetchedModuleData.value) {
      await getSchoolModules();
    }

    if (
      (to.name === "Portfolio" ||
        to.name === "Tasks" ||
        to.name === "TaskInfo" ||
        to.name === "MyTasks") &&
      !globals.value.schoolModules.includes("plusdocument")
    ) {
      return { name: "Unauthorized" };
    }

    if (
      (to.name === "MyRatings" ||
        to.name === "MyStudents" ||
        to.name === "MyMeetings") &&
      !globals.value.schoolModules.includes("feedback")
    ) {
      return { name: "Unauthorized" };
    }
  }

  if (!router.hasRoute(to.name)) {
    return { name: "Error" };
  }

  if (!isAuthorized(to)) {
    return { name: "Unauthorized" };
  }
});

const isAuthorized = (route) => {
  const { matched } = route;

  if (!matched[0]?.meta?.roleRequired && !route?.meta?.roleRequired) {
    return true;
  }

  if (
    matched[0]?.meta?.roleRequired &&
    !userHasRole(matched[0].meta.roleRequired)
  ) {
    return false;
  }

  if (route?.meta?.roleRequired && !userHasRole(route.meta.roleRequired)) {
    return false;
  }

  return true;
};

const userHasRole = (roles) => {
  const { role: userRoles } = JSON.parse(localStorage.getItem("user"));

  return userRoles.some((role) => roles.includes(role));
};

router.afterEach((to, from) => {
  document.title = `${to.meta.title} - Catwise`;
});

export default router;
