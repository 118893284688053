<!-- This is a work in progress, not finished yet.
    Background color is not working. Might have something to do with the runtime rendering,
-->

<template>
  <q-banner v-bind="$attrs" :class="`tw-bg-${banner.color}`">
    <template v-slot:avatar>
      <q-icon :name="banner.icon" size="md" />
    </template>
    <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </q-banner>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  type: {
    type: String,
    default: "info",
    validator: (value) => {
      return ["info", "warning", "error", "success"].includes(value);
    },
  },
});

const typeMappings = {
  info: { color: "primary", icon: "info" },
  warning: { color: "warning", icon: "warning" },
  error: { color: "negative", icon: "error" },
  success: { color: "positive", icon: "check_circle" },
};

const banner = computed(() => {
  return typeMappings[props.type];
});
</script>

<style lang="scss">
div.q-banner {
  min-height: unset;
  border-radius: $generic-border-radius;
}
</style>
