import useHelpers from "composables/useHelpers";
const { mappedTerms, checkRole } = useHelpers();

const routes = [
  {
    path: "/webmaster",
    meta: {
      roleRequired: ["webmaster"],
    },
    children: [
      {
        path: "",
        name: "WebmasterIndex",
        component: () => import("pages/admin/LoginAsSchool.vue"),
        meta: { transition: "fade", title: "Inloggen als medewerker" },
      },
      {
        path: "login-as-employee",
        name: "LoginAsEmployee",
        component: () => import("pages/admin/LoginAsEmployee.vue"),
        meta: { transition: "fade", title: "Inloggen als medewerker" },
      },
      {
        path: "schools",
        name: "Schools",
        component: () => import("pages/admin/Schools.vue"),
        meta: { transition: "fade", title: "Scholen" },
      },
      {
        path: "login-as-school",
        name: "LoginAsSchool",
        component: () => import("pages/admin/LoginAsSchool.vue"),
        meta: { transition: "fade", title: "Inloggen als school" },
      },
      {
        path: "school-stats",
        name: "SchoolStats",
        component: () => import("pages/admin/SchoolStats.vue"),
        meta: { transition: "fade", title: "School Statistieken" },
      },
      {
        path: "login-as-student",
        name: "LoginAsStudent",
        component: () => import("pages/admin/LoginAsStudent.vue"),
        meta: {
          transition: "fade",
          title: `Inloggen als ${mappedTerms().student} `,
        },
      },
      {
        path: "nps-data",
        name: "NpsData",
        component: () => import("pages/admin/NpsData.vue"),
        meta: {
          transition: "fade",
          title: "NPS",
        },
      },
      {
        path: "tips",
        name: "Tips",
        component: () => import("pages/admin/Tips.vue"),
        meta: {
          transition: "fade",
          title: "Tips",
        },
      },
      {
        path: "synonyms",
        name: "Vak Synoniemen",
        component: () => import("pages/admin/Synonyms.vue"),
        meta: { transition: "fade", title: "Vak synoniemen" },
      },
      {
        path: "import-grades",
        name: "Cijfers Importeren",
        component: () => import("pages/admin/ImportGrades.vue"),
        meta: { transition: "fade", title: "Cijfers importeren" },
      },
      {
        path: "email-export",
        name: "E-mail Export",
        component: () => import("pages/admin/EmailExport.vue"),
        meta: { transition: "fade", title: "E-mail export" },
      },
      {
        path: "sync-logs",
        name: "SyncLogs",
        component: () => import("pages/admin/SyncLogs.vue"),
        meta: {
          transition: "fade",
          title: "Sync logs",
        },
      },
      {
        path: "logs",
        name: "Logs",
        component: () => import("pages/admin/LogPage.vue"),
        meta: { transition: "fade", title: "Logs" },
      },
    ],
  },
];

export default routes;
