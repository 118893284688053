import defaultRatingIcons from "../store/modules/components/ratingIcons";
import axios from "axios";
import { isArray } from "chart.js/helpers";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
      timeoutId = null;
    }, delay);
  };
};

const schoolId = localStorage.getItem("school_id");
const schoolName = localStorage.getItem("school_name");
const employeeId = localStorage.getItem("employee_id");
const tool = localStorage.getItem("tool");
const currentShortage = localStorage.getItem("employee_shortage");
let studentId = localStorage.getItem("student_id");
const userName = localStorage.getItem("user_name");
const firstName = localStorage.getItem("first_name");
const user = JSON.parse(localStorage.getItem("user"));

const checkRole = (role) => {
  if (user) {
    return !!user.role.includes(role);
  }
};

const roleName = (role) => {
  if (role === "teacher") return "docent";
  if (role === "decaan") return "organisator portfolio";
  if (role === "teamleader") return "teamleider";
  if (role === "organizer") return "organisator";
  if (role === "manager") return "applicatiebeheerder";
  if (role === "webmaster") return "webmaster";
  if (role === "student") return "leerling";
};

const isMentor = (shortageCurrentUser, mentorsArray) => {
  let isMentor = false;
  mentorsArray.forEach((mentor) => {
    if (mentor.shortage === shortageCurrentUser) {
      isMentor = true;
    }
  });
  return isMentor;
};

const replaceProtocolWithHttps = (url) => {
  // Check if the URL starts with 'http://' or 'https://'
  if (url.startsWith("http://")) {
    // Replace 'http://' with 'https://'
    return "https://" + url.substring(7);
  } else if (url.startsWith("https://")) {
    // The URL already uses 'https://' protocol, no need to change it
    return url;
  } else {
    // If the URL doesn't start with either 'http://' or 'https://', assume 'https://'
    return "https://" + url;
  }
};

const checkIfStringContainsWords = (string, word) => {
  const lowercaseInput = string.toLowerCase();
  return lowercaseInput.includes(word.toLowerCase());
};

function objectToQueryString(obj) {
  let queryString = "";
  for (const key in obj) {
    if (obj[key].length > 0) {
      queryString += `&${key}=${obj[key].join(",")}`;
    }
  }
  return queryString.slice(1); // Remove the leading '&'
}

const shorten = (text, maxLength = 100) => {
  if (!text) return "";
  if (text.length <= maxLength) return text;
  return text.substr(0, maxLength) + "...";
};

const mappedTerms = () => {
  let maps = {};
  if (tool !== "leerlingbespreking") {
    maps = {
      environment: "Studentbespreking",
      student: "student",
      students: "studenten",
      mentor: "coach",
      mentors: "coaches",
      mentorklas: "klas",
      mentorklassen: "klassen",
      mentorleerlingen: "studenten",
      mentorleerling: "student",
      mentorgroep: "coachgroep",
    };
  } else {
    maps = {
      environment: "Leerlingbespreking",
      student: "leerling",
      students: "leerlingen",
      mentor: "mentor",
      mentors: "mentoren",
      mentorklas: "mentorklas",
      mentorklassen: "mentorklassen",
      mentorleerlingen: "mentorleerlingen",
      mentorleerling: "mentorleerling",
      mentorgroep: "mentorgroep",
    };
  }
  return maps;
};

const mapStatus = (status) => {
  switch (status) {
    case "open":
      return "Open";
    case "closed_modify":
      return "Gesloten, aanpassingen nog mogelijk";
    case "closed_no_modify":
      return "Gesloten, aanpassingen niet meer mogelijk";
    case "archived":
      return "Afgerond, aan de slag!";
    case "concept":
      return "Concept";
  }
};

export const mapMeetingType = (type) => {
  switch (type) {
    case "meeting":
      return "Leerlingbespreking";
    case "transition_meeting":
      return "Bevorderingsbespreking";
  }
};

const isStudent = () => {
  return !!user.role.includes("student");
};

function base64ToBlob(base64, type = "application/octet-stream") {
  const binStr = atob(base64);
  const len = binStr.length;
  const arr = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }
  return new Blob([arr], { type: type });
}

const printItem = async (url, formData = null) => {
  let win = window.open("");
  win.document.write(
    "<html><head><title>Bijlage</title></head><body style='margin:0;padding:0;font-family: Helvetica'><div style='width:100%;background: #1d2344;height: 42px;'></div><div style='text-align:center;margin-top:20px'>Het PDF bestand wordt geladen, dit kan 10 tot 60 seconden duren...<br><img style='width:50px; margin-top:25px' src='images/loading-gif.gif'></div><div style='width:100%;background: #1d2344;height: 42px; position:absolute;bottom:0'></div></body></html>",
  );

  return new Promise((resolve, reject) => {
    axios
      .post(url, formData)
      .then((res) => {
        const pos = res.data.lastIndexOf('"');
        let data = res.data.slice(pos + 1);
        data = data.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "");

        const blob = base64ToBlob(data, "application/pdf");
        win.document.body.innerHTML = "";
        win.document.write(
          '<iframe src="' +
            URL.createObjectURL(blob) +
            '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>',
        );

        resolve();
      })
      .catch((e) => {
        win.document.body.innerHTML = "";
        win.document.write(
          "<html><head><title>Bijlage</title></head><body style='margin:0;padding:0;font-family: Helvetica'><div style='width:100%;background: #1d2344;height: 42px;'></div><div style='text-align:center;margin-top:20px'>Er is iets misgegaan, sluit dit venster en probeer het later opnieuw.</div><div style='width:100%;background: #1d2344;height: 42px; position:absolute;bottom:0'></div></body></html>",
        );
        reject(e);
      });
  });
};

const newPdfTabV2 = (res) => {
  const pos = res.lastIndexOf('"');
  let data = res.slice(pos + 1);
  data = data.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "");

  const win = window.open();
  const blob = base64ToBlob(data, "application/pdf");
  win.document.write(
    '<iframe src="' +
      URL.createObjectURL(blob) +
      '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>',
  );

  function base64ToBlob(base64, type = "application/octet-stream") {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
  }
};

const newTab = (data) => {
  window.open(data);
};

const getSuggestions = async (type) => {
  let suggestions = null;
  if (isArray(type)) {
    suggestions = await axios({
      method: "get",
      url: `/api/suggestions?types=${type.join(",")}`,
    });
  } else {
    suggestions = await axios({
      method: "get",
      url: `/api/suggestions?type=${type}`,
    });
  }

  return suggestions.data;
};

const ratingIcons = defaultRatingIcons.state().icons;

/**
 * This will create a component name from a file path
 * @param {string} filePath
 * @returns {string | null} The component name or null if no match is found
 */
export const extractComponentName = (filePath) => {
  // Use a regular expression to match the component name
  const match = filePath.match(/\/([^\/]+)\.vue$/);

  // Check if a match is found
  if (match && match[1]) {
    // Return the captured component name
    return match[1];
  }

  // Return null if no match is found
  return null;
};

//Allowed file types for attachments
let allowedFileTypes = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.oasis.opendocument.text",
  "image/jpg",
  "image/png",
  "image/heic",
  "image/jpeg",
  "application/x-iwork-pages-sffpages",
  "application/x-iwork-keynote-sffkey",
  "application/x-iwork-numbers-sffnumbers",
];

let appleFileTypes = [
  "application/x-iwork-pages-sffpages",
  "application/x-iwork-keynote-sffkey",
  "application/x-iwork-numbers-sffnumbers",
];

let allowedFileExtensions = [
  ".pdf",
  ".jpg",
  ".jpeg",
  ".doc",
  ".docx",
  ".ppt",
  ".pptx",
  ".xls",
  ".xlsx",
  ".odt",
  ".png",
  ".pages",
  ".key",
  ".numbers",
];

const mapRatingIconToName = (value) => {
  let name = "";
  ratingIcons.forEach((icon) => {
    if (icon.id === value) {
      name = icon.name;
    }
  });

  return name;
};

let invalidFileMessage =
  "Dit bestand is niet toegestaan. Toegestane bestanden: " +
  allowedFileExtensions.join(", ");

//Export the functions
const useHelpers = () => ({
  debounce,
  mappedTerms,
  schoolId,
  schoolName,
  employeeId,
  tool,
  currentShortage,
  userName,
  user,
  getSuggestions,
  ratingIcons,
  firstName,
  studentId,
  mapStatus,
  isStudent,
  checkRole,
  roleName,
  shorten,
  newPdfTabV2,
  newTab,
  objectToQueryString,
  checkIfStringContainsWords,
  replaceProtocolWithHttps,
  printItem,
  isMentor,
  allowedFileTypes,
  appleFileTypes,
  invalidFileMessage,
  mapRatingIconToName,
  allowedFileExtensions,
  mapMeetingType,
});

export default useHelpers;
