<template>
  <MyTasksMenuItem v-if="checkRole('student')" />
  <NotificationsMenuItem />
  <div
    class="tw-group tw-relative tw-px-1"
    v-if="
      checkRole('webmaster') ||
      checkRole('organizer') ||
      checkRole('manager') ||
      checkRole('teamleader') ||
      checkRole('decaan') ||
      checkRole('teacher')
    "
  >
    <q-icon name="fa-light fa-circle-question  fa-swap-opacity" size="20px" />
    <div
      class="tw-absolute tw--right-4 tw--mx-4 tw-hidden tw-min-w-[223px] tw-border-b-4 tw-bg-white tw-px-4 tw-py-4 tw-shadow group-hover:tw-block 2xl:tw-right-0"
    >
      <div class="tw-flex hover:tw-underline">
        <router-link to="/help-formulier"> Helpformulier </router-link>
      </div>
      <div class="tw-flex tw-items-center tw-gap-x-2 hover:tw-underline">
        <a href="https://catwise.nl/training" target="_blank">Trainingen</a>
        <q-icon name="fa-solid fa-arrow-up-right-from-square" size="10px" />
      </div>
      <div class="tw-flex tw-items-center tw-gap-x-2 hover:tw-underline">
        <a href="https://help.catwise.nl/" target="_blank">Kennisbank</a>
        <q-icon name="fa-solid fa-arrow-up-right-from-square" size="10px" />
      </div>
      <div class="tw-flex tw-items-center tw-gap-x-2 hover:tw-underline">
        <a href="https://help.catwise.nl/article/108-updates" target="_blank"
          >Updates</a
        >
        <q-icon name="fa-solid fa-arrow-up-right-from-square" size="10px" />
      </div>
      <div class="tw-flex hover:tw-underline">
        <router-link to="/feedback-formulier"
          >Feedback sturen naar Catwise</router-link
        >
      </div>
    </div>
  </div>

  <router-link
    class="tw-group tw-border-b-2 tw-border-white tw-px-1 tw-py-3 hover:tw-underline [&.router-link-active]:tw-border-primary [&.router-link-active]:tw-bg-gray-100 [&.router-link-active]:tw-text-primary [&.router-link-active]:hover:tw-no-underline"
    to="/settings"
    v-if="globals.showSettingsPage"
  >
    <q-icon name="fa-light fa-gear" size="20px" />
  </router-link>

  <div class="tw-cursor-pointer tw-pl-1 hover:tw-opacity-50" @click="logout()">
    <q-icon
      name="fa-light fa-arrow-right-from-bracket fa-swap-opacity"
      size="20px"
    />
  </div>
</template>

<script setup>
import NotificationsMenuItem from "@/components/global/NotificationsMenuItem.vue";
import MyTasksMenuItem from "@/components/global/MyTasksMenuItem.vue";
import useHelpers from "@/composables/useHelpers";
import { onMounted } from "vue";
import { useGlobalStore } from "@/stores/global/global";
import axios from "axios";
import { storeToRefs } from "pinia";

const { checkRole, schoolId } = useHelpers();

// Store
const globalStore = useGlobalStore();
const { globals } = storeToRefs(globalStore);

onMounted(async () => {
  await checkSSO();
});

const logout = () => {
  localStorage.clear();
  window.location.reload();
};

const checkSSO = async () => {
  let schoolSSO = localStorage.getItem("school_sso");
  if (!schoolSSO) {
    let url = "/api/schools/" + schoolId;
    if (checkRole("student")) {
      url += "?student=true";
    }

    return axios.get(url).then((res) => {
      localStorage.setItem("school_sso", res.data.data.sso);
      if (res.data.data.sso === "1" || res.data.data.sso === 1) {
        globals.value.showSettingsPage = false;
      }
    });
  } else {
    if (schoolSSO === "1") {
      globals.value.showSettingsPage = false;
    }
  }
};
</script>
