<template>
  <div
    class="tw-relative tw-inline tw-cursor-pointer tw-whitespace-pre-line tw-bg-white tw-underline tw-decoration-dotted"
    :class="{
      'tw-text-red-700': errors,
    }"
  >
    {{ modelValue ? modelValue : label }}
    <q-icon
      size="10px"
      name="fa-duotone fa-pencil"
      class="tw-absolute tw-right-0 tw-top-1/2 -tw-translate-y-px"
    />
    <q-popup-proxy ref="proxy">
      <div class="tw-bg-white tw-p-2">
        <c-input
          autofocus
          v-model="modelValue"
          @keydown="handleKeyPress"
          clearable
        />
        <div class="tw-flex tw-justify-end">
          <c-button class="tw-mt-2" @click="closePopup">Opslaan</c-button>
        </div>
      </div>
    </q-popup-proxy>
  </div>
</template>

<script setup>
import { ref, watch, onBeforeMount } from "vue";
import CInput from "components/wrappers/input/CInput.vue";

const proxy = ref(null);
const modelValue = ref("");
const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: String,
    required: false,
    default: "",
  },
  label: {
    type: String,
    required: false,
  },
  errors: {
    type: [Object, String],
    required: false,
  },
});

onBeforeMount(() => {
  modelValue.value = props.modelValue;
});

watch(modelValue, () => {
  emit("update:modelValue", modelValue.value);
});

const handleKeyPress = (event) => {
  if (event.key === "Escape" || event.key === "Enter") {
    closePopup();
  }
};

const closePopup = () => {
  proxy.value.hide();
};
</script>
