<template>
  <div
    class="tw-relative tw-bg-secondary tw-py-1 tw-pr-12 tw-text-center tw-font-heading tw-text-white"
    v-if="globals.statusMessage && globals.statusMessage.active"
  >
    {{ globals.statusMessage.message }}
    <div
      @click="globals.statusMessage.active = false"
      class="tw-group tw-absolute tw-right-2 tw-top-1/2 tw-aspect-square tw-h-5 tw--translate-y-1/2 tw-cursor-pointer tw-rounded-full tw-bg-secondary-800 tw-transition-all hover:tw-bg-secondary-900"
    >
      <div
        class="tw-absolute tw-left-1/2 tw-top-1/2 tw-h-3 tw-w-[.15rem] tw-origin-center tw--translate-x-1/2 tw--translate-y-1/2 tw-rotate-45 tw-transform tw-rounded-full tw-bg-white tw-transition-all group-hover:tw-rotate-[60deg]"
      ></div>
      <div
        class="tw-absolute tw-left-1/2 tw-top-1/2 tw-h-3 tw-w-[.15rem] tw-origin-center tw--translate-x-1/2 tw--translate-y-1/2 tw--rotate-45 tw-transform tw-rounded-full tw-bg-white tw-transition-all group-hover:tw-rotate-[-60deg]"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { useGlobalStore } from "@/stores/global/global";
import { onMounted } from "vue";
import axios from "axios";
import { storeToRefs } from "pinia";

// Store
const globalStore = useGlobalStore();
const { globals } = storeToRefs(globalStore);

onMounted(async () => {
  if (
    globals.value.statusMessage &&
    globals.value.statusMessage.message === null
  )
    await getStatusMessage();
});

const getStatusMessage = async () => {
  globals.value.statusMessage = {
    active: true,
    message:
      "Woensdagochtend 21 augustus tussen 6.00 en 8.30 uur wordt een grote update uitgevoerd. Hierdoor is de tool wat minder goed bereikbaar.",
  };
  // return axios
  //   .get("/api/status-message")
  //   .then((res) => {
  //     globals.value.statusMessage = {
  //       active: !!res.data.messages,
  //       message: res.data.messages,
  //     };
  //   })
  //   .catch((err) => {
  //     globals.value.statusMessage = {
  //       active: false,
  //       message: null,
  //     };
  //     console.log(err);
  //   });
};
</script>
